/* Colors.css */

.bg-primary {
  background-color: $color_primary;

  h2,
  h3,
  h4,
  p {
    color: white;
  }
}

.bg-secondary {
  background-color: $color_secondary;
  color: white;

  h2,
  h3,
  h4,
  p,
  a {
    color: white;
  }
}

.bg-fourth {
  background-color: $color_four;

  h2,
  h3,
  h4,
  p,
  a {
    color: white;
  }
}

.bg-white {
  background-color: white;
}

.bg-primary,
.bg-secondary a,
.bg-third a {
  color: white;

  &:hover {
    color: white;
  }
}

.text-primary {
  color: $color_primary;
}
