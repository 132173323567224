/* ContactList.css */

ul.contact-list {
  list-style:none;
  @include resp-prop-half("margin-top", $offset-g2);
  @include resp-prop-half("margin-bottom", $offset-g2);
  padding:0;

  @media (max-width: $bp-xs-1) {
    margin-bottom:0;
  }
  
  &.inline {
    li {
      @media (min-width: $bp-sm-1) {
        display:inline;
        padding:0 $offset-g1;
        border-right:1px dotted white;
        &:last-child {
          border-right:none;
        }
      }      
    }
  }

  li {
    margin:0;
    padding:0;    
    @media (max-width: $bp-xs-1) {
      margin-bottom:$offset-g1;
    }

    &.email-full {
      @media (max-width: $bp-sm-1) {
        display:none;
      }
    }
    &.email-compact {
      @media (min-width: $bp-sm) {
        display:none;
      }
    }

    svg {
      width:20px !important;
      margin-right:$offset-1;
    }

    a {
      color:white;
      text-decoration:none;

      &:hover {
        text-decoration:underline;
      }
    }
  }
}
