/* Typography.css */

body {
  font-family: $base-font-family;
	font-size: $base-font-size;
  color: $base-font-color;
  line-height: $base-font-line-height;
  letter-spacing: 0.1em;
  color: $color_font-default;

  @media (min-width: $bp-sm) and (max-width: $bp-xs-1) {
    font-size: 1em;
    line-height: 1.6em;
  }
  @media (min-width: $bp-tablet) and (max-width: $bp-sm-1) {
    font-size:0.9em;
    line-height: 1.4em;
  }
  @media (max-width: $bp-tablet-1) {
    font-size: 0.8em;
    line-height: 1.2em;
  }
}

a {
  color: $color_font-hyperlink;
  &:hover {
    color: $color_font-hyperlink-hover;
  }
  &:active {
    color: #ff9900;
  }
}

.color-link-accent {
  color: $color_font-hyperlink-accent;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: black;
  font-family: "Century Gothic", "Apple Gothic", sans-serif;
  font-weight: normal;
}

p {
  margin: 0 0 $offset-g2 0;
  color: $color_font-default;
  font-size: $base-font-size;
  color: $base-font-color;
  line-height: $base-font-line-height;
}

h1 {
  font-size: 50px;
  line-height: 60px;
  font-weight: 300;
  color: $color_font-h1;
  text-transform: capitalize;
  @include resp-prop-half("margin-top", $offset-g2);
  @include resp-prop-half("margin-bottom", $offset-g2);

  @media (min-width: $bp-sm) and (max-width: $bp-xs-1) {
    font-size: 40px;
    line-height: 50px;
  }
  @media (min-width: $bp-xs) and (max-width: $bp-sm-1) {
    font-size: 36px;
    line-height: 40px;
  }
  @media (max-width: $bp-xs-1) {
    font-size: 28px;
    line-height: 30px;
  }
}

h2 {
  font-size: 40px;
  line-height: 40px;
  font-weight: 300;
  letter-spacing: 0.1em;
  color: $color_font-h2;
  text-transform: capitalize;
  @include resp-prop-half("margin-top", $offset-g2);
  @include resp-prop-half("margin-bottom", $offset-g2);

  @media (min-width: $bp-sm) and (max-width: $bp-xs-1) {
    font-size: 36px;
    line-height: 40px;
  }
  @media (min-width: $bp-xs) and (max-width: $bp-tablet-1) {
    font-size: 24px;
    line-height: 30px;
  }
  @media (max-width: $bp-xs-1) {
    font-size: 20px;
    line-height: 24px;
  }
}

h3 {
  font-size: 20px;
  line-height: $offset-g2;
  font-weight: 700;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: $color_font-h3;

  a {
    text-decoration:none;
  }

  @media (min-width: $bp-sm) and (max-width: $bp-xs-1) {
    font-size: 16px;
    line-height: 20px;
  }
  @media (max-width: $bp-sm-1) {
    font-size: 14px;
    line-height: 20px;
  }
}

h4 {
  font-size: 16px;
  line-height: $offset-2;
  font-weight: 700;
  letter-spacing: 0.1em;
  text-transform: capitalize;
  color: $color_font-h4;
  margin:$offset-g2 0 $offset-g1 0;
}

/* <hr> */
hr {
  margin-top: $offset-g2;
  margin-bottom: $offset-g2;
  border: 0;
  border-top: 1px solid #fff;
}

hr.dotted {
  border-style: dotted;
}

hr.small {
  max-width: 60px;
}


/* REF: https://jsfiddle.net/h8uxmoj4/ */
/* https://www.toptal.com/designers/htmlarrows/punctuation/bullet/ */
ul.bullets {
  padding-left: 0;

  li {
    list-style: none;
    margin:0 0 $offset-1 0;
    padding-left: $offset-2;
    position: relative;

    &::before {
      left: 0;
      position: absolute;
      content: "\2022";
    }
  }

  &.check {
    li {
      padding-left: 25px;

      &::before {
        content: "\2713";
      }
    }
  }
}