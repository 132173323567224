/* Header.css */

.header-banner {  
  min-height:60px;//because of toggle burger menu button
  // background:white;

  @media (max-width: $bp-tablet-1) {
    // background:orange;
    padding:5px;
  }

  .section-inner {
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // background:yellow;
    // @include resp-prop-half("padding", $offset-g1);
    text-align:center;
  
    .logo-img {
      font-size: 0;
      line-height: 0;

      img {
        width: 100%;
        height: auto;
        max-width:440px;

        @media (max-width: $bp-tablet-1) {
          width:80%;
        }
      }
    } 
    
  }  
}

