/* Contact.css */

footer {
  text-align:center;

  .content-inner {
    display: inline-block;
    max-width: $max-width;
    margin: 0 auto;
    // padding: 0 $offset-g2;
  }
  p.copyright {
    padding: 0 0 $offset-g2 0;
    margin: 0 auto;
  }
}

