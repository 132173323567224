.toolbar {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 102;
  height: calc(#{$offset-g2 * 2});
}

.toolbar__navigation {
  display: flex;
  height: 100%;
  align-items: center;
  padding: 0; // $offset-g1;
}

.toolbar.no-toolbar {
  width: calc(#{$offset-g2 * 2});
  @media (max-width:$bp-xxs-1){//210503
    width:$offset-g2;
    // height:$offset-g2;
  }
}

.toolbar__toggle-button {
  width: 100%;
  height: 100%;
}

.toolbar__logo {
  margin-left: $offset-g1;
}

.toolbar__logo a {
  color: white;
  text-decoration: none;
  font-size: 1.4rem;
}

.spacer {
  flex: 1;
}

.toolbar__navigation-items ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;

  li {
    padding: 0 0.5rem;

    a {
      color: white;
      text-decoration: none;

      &:hover,
      &:active {
        color: orange;
      }
    }
  }
}

// @media (max-width: $bp-sm-1) {
//   .toolbar__navigation-items {
//     display: none;
//   }
// }

// @media (min-width: $bp-sm) {
//   .toolbar__toggle-button {
//     display: none;
//   }

//   .toolbar__logo {
//     margin-left: 0;
//   }
// }
