/* App.scss */
/* Variables.scss */
/* color */
/* base font */
/* offset: margins & paddings */
/* responsive breakpoints */
/* table */
/* Colors.css */
.bg-primary {
  background-color: #263762; }
  .bg-primary h2,
  .bg-primary h3,
  .bg-primary h4,
  .bg-primary p {
    color: white; }

.bg-secondary {
  background-color: #8b000b;
  color: white; }
  .bg-secondary h2,
  .bg-secondary h3,
  .bg-secondary h4,
  .bg-secondary p,
  .bg-secondary a {
    color: white; }

.bg-fourth {
  background-color: #01395a; }
  .bg-fourth h2,
  .bg-fourth h3,
  .bg-fourth h4,
  .bg-fourth p,
  .bg-fourth a {
    color: white; }

.bg-white {
  background-color: white; }

.bg-primary,
.bg-secondary a,
.bg-third a {
  color: white; }
  .bg-primary:hover,
  .bg-secondary a:hover,
  .bg-third a:hover {
    color: white; }

.text-primary {
  color: #263762; }

/* Mixins.scss */
/* Base.scss */
body {
  border-color: #dfdfdf;
  border: 0;
  margin: 0; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

/* (END) from index.css */
.App {
  text-align: left; }

section {
  box-sizing: border-box;
  text-align: center;
  padding: 30px; }
  @media (max-width: 639px) {
    section {
      padding: calc(30px / 2); } }
  @media (max-width: 767px) {
    section {
      text-align: left; } }
  section > .content-inner,
  section > .section-inner {
    display: inline-block;
    max-width: 900px;
    margin: 0 auto; }
    section > .content-inner.body-text,
    section > .section-inner.body-text {
      text-align: left; }

.display-none {
  display: none; }

.display-block {
  display: block; }

.display-inline-block {
  display: inline-block; }

/* offsets */
.pad-icon-left {
  margin-left: 10px; }

/* CookieConsent */
.CookieConsent a {
  color: white; }
  .CookieConsent a:hover {
    color: white; }

/* Typography.css */
body {
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
  font-size: 1em;
  color: #333;
  line-height: 1.6em;
  letter-spacing: 0.1em;
  color: #333; }
  @media (min-width: 768px) and (max-width: 479px) {
    body {
      font-size: 1em;
      line-height: 1.6em; } }
  @media (min-width: 640px) and (max-width: 767px) {
    body {
      font-size: 0.9em;
      line-height: 1.4em; } }
  @media (max-width: 639px) {
    body {
      font-size: 0.8em;
      line-height: 1.2em; } }

a {
  color: black; }
  a:hover {
    color: #8b000b; }
  a:active {
    color: #ff9900; }

.color-link-accent {
  color: #20b1f3; }

h1,
h2,
h3,
h4,
h5,
h6 {
  color: black;
  font-family: "Century Gothic", "Apple Gothic", sans-serif;
  font-weight: normal; }

p {
  margin: 0 0 30px 0;
  color: #333;
  font-size: 1em;
  color: #333;
  line-height: 1.6em; }

h1 {
  font-size: 50px;
  line-height: 60px;
  font-weight: 300;
  color: black;
  text-transform: capitalize;
  margin-top: 30px;
  margin-bottom: 30px; }
  @media (max-width: 639px) {
    h1 {
      margin-top: calc(30px / 2); } }
  @media (max-width: 639px) {
    h1 {
      margin-bottom: calc(30px / 2); } }
  @media (min-width: 768px) and (max-width: 479px) {
    h1 {
      font-size: 40px;
      line-height: 50px; } }
  @media (min-width: 480px) and (max-width: 767px) {
    h1 {
      font-size: 36px;
      line-height: 40px; } }
  @media (max-width: 479px) {
    h1 {
      font-size: 28px;
      line-height: 30px; } }

h2 {
  font-size: 40px;
  line-height: 40px;
  font-weight: 300;
  letter-spacing: 0.1em;
  color: black;
  text-transform: capitalize;
  margin-top: 30px;
  margin-bottom: 30px; }
  @media (max-width: 639px) {
    h2 {
      margin-top: calc(30px / 2); } }
  @media (max-width: 639px) {
    h2 {
      margin-bottom: calc(30px / 2); } }
  @media (min-width: 768px) and (max-width: 479px) {
    h2 {
      font-size: 36px;
      line-height: 40px; } }
  @media (min-width: 480px) and (max-width: 639px) {
    h2 {
      font-size: 24px;
      line-height: 30px; } }
  @media (max-width: 479px) {
    h2 {
      font-size: 20px;
      line-height: 24px; } }

h3 {
  font-size: 20px;
  line-height: 30px;
  font-weight: 700;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: black; }
  h3 a {
    text-decoration: none; }
  @media (min-width: 768px) and (max-width: 479px) {
    h3 {
      font-size: 16px;
      line-height: 20px; } }
  @media (max-width: 767px) {
    h3 {
      font-size: 14px;
      line-height: 20px; } }

h4 {
  font-size: 16px;
  line-height: 20px;
  font-weight: 700;
  letter-spacing: 0.1em;
  text-transform: capitalize;
  color: black;
  margin: 30px 0 15px 0; }

/* <hr> */
hr {
  margin-top: 30px;
  margin-bottom: 30px;
  border: 0;
  border-top: 1px solid #fff; }

hr.dotted {
  border-style: dotted; }

hr.small {
  max-width: 60px; }

/* REF: https://jsfiddle.net/h8uxmoj4/ */
/* https://www.toptal.com/designers/htmlarrows/punctuation/bullet/ */
ul.bullets {
  padding-left: 0; }
  ul.bullets li {
    list-style: none;
    margin: 0 0 10px 0;
    padding-left: 20px;
    position: relative; }
    ul.bullets li::before {
      left: 0;
      position: absolute;
      content: "\2022"; }
  ul.bullets.check li {
    padding-left: 25px; }
    ul.bullets.check li::before {
      content: "\2713"; }

/* Table.scss */
.table {
  border: 1px solid #dfdfdf;
  margin: 30px 0;
  display: block;
  width: 100%;
  max-width: 800px;
  border-collapse: collapse; }
  .table tr {
    border-bottom: 1px solid #dfdfdf; }
    .table tr th {
      background: #dfdfdf;
      padding: 5px 10px;
      font-weight: bold; }
    .table tr:nth-child(odd) {
      background: #f9f9f9; }
    .table tr td {
      padding: 5px 10px; }

/* Header.css */
.header-banner {
  min-height: 60px; }
  @media (max-width: 639px) {
    .header-banner {
      padding: 5px; } }
  .header-banner .section-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center; }
    .header-banner .section-inner .logo-img {
      font-size: 0;
      line-height: 0; }
      .header-banner .section-inner .logo-img img {
        width: 100%;
        height: auto;
        max-width: 440px; }
        @media (max-width: 639px) {
          .header-banner .section-inner .logo-img img {
            width: 80%; } }

/* Backdrop.scss */
.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 100; }

/* TitleTextImg.css */
section.img-text {
  width: 100%;
  padding: 0; }
  @media (min-width: 640px) {
    section.img-text {
      display: flex;
      flex-direction: row; } }
  section.img-text .section-inner-img {
    width: 50%;
    background-image: url(/static/media/bg_jag-porsche-covers.4037af4a.jpg);
    background-position: left center;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-size: cover;
    -o-background-size: cover; }
    @media (max-width: 639px) {
      section.img-text .section-inner-img {
        width: 100%;
        height: 360px; } }
    @media (max-width: 479px) {
      section.img-text .section-inner-img {
        height: 180px; } }
  section.img-text .section-inner-text {
    width: 50%; }
    section.img-text .section-inner-text .content-inner {
      padding: 30px;
      text-align: left;
      max-width: 490px; }
      @media (max-width: 639px) {
        section.img-text .section-inner-text .content-inner {
          padding: calc(30px / 2); } }
      section.img-text .section-inner-text .content-inner .body {
        font-size: 1.2em;
        line-height: 1.6em; }
    @media (max-width: 639px) {
      section.img-text .section-inner-text {
        width: 100%; }
        section.img-text .section-inner-text .content-inner {
          max-width: auto; }
          section.img-text .section-inner-text .content-inner h2 {
            margin-top: 0; } }
  section.img-text#about .section-inner-img {
    background-image: url(/static/media/aston-martin-front.1aa4ac3e.jpg);
    background-position: left center; }
  section.img-text#maintenance .section-inner-img {
    background-image: url(/static/media/tyres-checked.18fa7be2.jpg);
    background-position: left center; }
  section.img-text#transport .section-inner-img {
    background-image: url(/static/media/car-transport-services.a2e84410.jpg);
    background-position: right center; }
  section.img-text#accessories .section-inner-img {
    background-image: url(/static/media/accessories-bag.a584b41f.jpg);
    background-position: right center; }

/* ContactList.css */
ul.contact-list {
  list-style: none;
  margin-top: 30px;
  margin-bottom: 30px;
  padding: 0; }
  @media (max-width: 639px) {
    ul.contact-list {
      margin-top: calc(30px / 2); } }
  @media (max-width: 639px) {
    ul.contact-list {
      margin-bottom: calc(30px / 2); } }
  @media (max-width: 479px) {
    ul.contact-list {
      margin-bottom: 0; } }
  @media (min-width: 767px) {
    ul.contact-list.inline li {
      display: inline;
      padding: 0 15px;
      border-right: 1px dotted white; }
      ul.contact-list.inline li:last-child {
        border-right: none; } }
  ul.contact-list li {
    margin: 0;
    padding: 0; }
    @media (max-width: 479px) {
      ul.contact-list li {
        margin-bottom: 15px; } }
    @media (max-width: 767px) {
      ul.contact-list li.email-full {
        display: none; } }
    @media (min-width: 768px) {
      ul.contact-list li.email-compact {
        display: none; } }
    ul.contact-list li svg {
      width: 20px !important;
      margin-right: 10px; }
    ul.contact-list li a {
      color: white;
      text-decoration: none; }
      ul.contact-list li a:hover {
        text-decoration: underline; }

/* TitleSection.scss */
section.title {
  text-align: center;
  padding-top: calc(30px * 2);
  /* (END) h2 */
  /* (END) h3 */ }
  @media (max-width: 639px) {
    section.title {
      padding-top: calc(calc(30px * 2) / 2); } }
  section.title h2 {
    display: inline-block;
    margin: 0 auto;
    padding: 0; }
  section.title h3 {
    margin: 30px auto 0 auto;
    display: inline-block;
    position: relative; }
  section.title .body {
    margin-top: 30px; }
  section.title.bg-secondary .body {
    font-weight: bold; }

/* MainImage.css */
/*
  */
.main-img {
  width: 75%;
  height: auto;
  /* min-height: 60vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  margin: 0 auto; }

div.main-img img {
  width: 100%;
  height: auto; }

@media (max-width: 639px) {
  .main-img {
    width: 100%; } }

/* ImgBanner.css */
.callout {
  display: table;
  width: 100%;
  height: 400px;
  color: white;
  background-color: #333;
  /* background: url(../../assets/pininfarina.jpg) no-repeat left top scroll; */
  /* -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover; */ }

.text-vertical-center {
  width: 90%;
  height: 100%;
  /* min-height: 60vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  margin: 0 auto; }

.text-vertical-center h1 {
  margin: 0;
  padding: 0;
  /* font-size: 4.5em;
      font-weight: 700; */ }

.text-vertical-center h1,
.text-vertical-center h3 {
  color: white;
  text-shadow: 2px 2px 5px black;
  /* font-size: 4.5em;
      font-weight: 700; */ }

@media (max-width: 639px) {
  .text-vertical-center h1 {
    font-size: 2.5em; } }

/* Clients.css */
/* grid */
/*
  https://www.freecodecamp.org/news/how-to-create-an-image-gallery-with-css-grid-e0f0fd666a5c/
  */
.css-grid.services {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  /* grid-template-rows: repeat(3, 10vw); */
  grid-gap: 15px;
  max-width: 900px;
  margin: 0 auto; }
  .css-grid.services .item {
    width: 100%;
    height: 100%;
    object-fit: cover; }
    .css-grid.services .item .img-area img {
      width: 100%;
      height: auto; }
    .css-grid.services .item .text-area {
      padding: 0 0 30px 0;
      text-align: left; }
      .css-grid.services .item .text-area .body {
        padding-right: 15px; }
      .css-grid.services .item .text-area h3 {
        margin: 15px auto; }
      .css-grid.services .item .text-area p {
        margin-bottom: 0; }

@media (min-width: 640px) and (max-width: 990px) {
  .css-grid.services {
    grid-template-columns: repeat(2, 1fr);
    /* grid-template-rows: repeat(2, 10vw); */
    grid-row-gap: 30px; }
  .css-grid.services .item .icon-area > .fa-stack {
    /* background: pink; */
    /* display: inline-block;
    width: 40%; */
    font-size: 3em; } }

@media (max-width: 639px) {
  .css-grid.services {
    grid-template-columns: repeat(1, 1fr);
    grid-row-gap: 30px; }
    .css-grid.services .item {
      display: flex; }
      .css-grid.services .item .img-area {
        width: 30%; }
      .css-grid.services .item .text-area {
        width: 70%;
        text-align: left;
        padding: 0 0 0 15px; } }
      @media (max-width: 639px) and (max-width: 479px) {
        .css-grid.services .item .text-area .body {
          display: none; } }

@media (max-width: 639px) {
        .css-grid.services .item .text-area h3 {
          margin: 0; }
        .css-grid.services .item .text-area p {
          margin: 0; } }

@media (max-width: 479px) {
  .css-grid.services .item .icon-area > .fa-stack {
    font-size: 1.2em; }
  .css-grid.services .item .text-area {
    flex-grow: 1;
    text-align: left;
    padding: 0 0 0 15px; } }

/* Contact.css */
footer {
  text-align: center; }
  footer .content-inner {
    display: inline-block;
    max-width: 900px;
    margin: 0 auto; }
  footer p.copyright {
    padding: 0 0 30px 0;
    margin: 0 auto; }

.toolbar {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 102;
  height: calc(60px); }

.toolbar__navigation {
  display: flex;
  height: 100%;
  align-items: center;
  padding: 0; }

.toolbar.no-toolbar {
  width: calc(60px); }
  @media (max-width: 399px) {
    .toolbar.no-toolbar {
      width: 30px; } }

.toolbar__toggle-button {
  width: 100%;
  height: 100%; }

.toolbar__logo {
  margin-left: 15px; }

.toolbar__logo a {
  color: white;
  text-decoration: none;
  font-size: 1.4rem; }

.spacer {
  flex: 1 1; }

.toolbar__navigation-items ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex; }
  .toolbar__navigation-items ul li {
    padding: 0 0.5rem; }
    .toolbar__navigation-items ul li a {
      color: white;
      text-decoration: none; }
      .toolbar__navigation-items ul li a:hover, .toolbar__navigation-items ul li a:active {
        color: orange; }

.side-drawer {
  height: 100%;
  background: white;
  box-shadow: 1px 0 7px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 260px;
  max-width: 400px;
  z-index: 101;
  -webkit-transform: translateX(-270px);
          transform: translateX(-270px);
  -webkit-transition: -webkit-transform 0.3s ease-out;
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out; }
  .side-drawer.open {
    -webkit-transform: translateX(0);
            transform: translateX(0); }
  .side-drawer ul {
    height: 100%;
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0;
    padding: 0; }
    .side-drawer ul li {
      margin: 0;
      padding: 0; }
      .side-drawer ul li a {
        text-decoration: none;
        text-transform: capitalize;
        font-size: 1.2rem;
        text-align: left;
        display: block;
        padding: 10px 15px; }

/* DrawerToggleButton.scss */
.toggle-button {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 10px 15px; }
  @media (max-width: 399px) {
    .toggle-button {
      padding: 5px 7px;
      background: white;
      height: 30px;
      margin: 20px 0 0 0; }
      .toggle-button .nav-icon {
        zoom: 0.5; } }
  .toggle-button .nav-icon {
    width: 30px; }
    .toggle-button .nav-icon:after, .toggle-button .nav-icon:before,
    .toggle-button .nav-icon div {
      background-color: black;
      border-radius: 3px;
      content: "";
      display: block;
      height: 4px;
      margin: 7px 0;
      -webkit-transition: all 0.2s ease-in-out;
      transition: all 0.2s ease-in-out; }

.toolbar:not(.side-drawer-open) {
  background: rgba(255, 255, 255, 0.75);
  -webkit-transition: all 0.2s ease-in;
  transition: all 0.2s ease-in; }
  .toolbar:not(.side-drawer-open):hover {
    background: white; }
  @media (max-width: 399px) {
    .toolbar:not(.side-drawer-open) {
      background: transparent; }
      .toolbar:not(.side-drawer-open):hover {
        background: transparent; } }

.toolbar:not(.side-drawer-open) .toggle-button:hover .nav-icon:before, .toolbar:not(.side-drawer-open) .toggle-button:hover .nav-icon:after,
.toolbar:not(.side-drawer-open) .toggle-button:hover .nav-icon div {
  width: 20px; }

.toolbar:not(.side-drawer-open) .toggle-button:hover .nav-icon:before {
  -webkit-transform: translateX(6px) translateY(5px) rotate(45deg);
          transform: translateX(6px) translateY(5px) rotate(45deg); }

.toolbar:not(.side-drawer-open) .toggle-button:hover .nav-icon:after {
  -webkit-transform: translateX(6px) translateY(-5px) rotate(-45deg);
          transform: translateX(6px) translateY(-5px) rotate(-45deg); }

.toolbar:not(.side-drawer-open) .toggle-button:hover .nav-icon div {
  -webkit-transform: scale(0);
          transform: scale(0); }

.toolbar.side-drawer-open .toggle-button .nav-icon:after, .toolbar.side-drawer-open .toggle-button .nav-icon:before,
.toolbar.side-drawer-open .toggle-button .nav-icon div {
  background-color: black; }

.toolbar.side-drawer-open .toggle-button .nav-icon:before {
  -webkit-transform: translateY(11px) rotate(135deg);
          transform: translateY(11px) rotate(135deg); }

.toolbar.side-drawer-open .toggle-button .nav-icon:after {
  -webkit-transform: translateY(-11px) rotate(-135deg);
          transform: translateY(-11px) rotate(-135deg); }

.toolbar.side-drawer-open .toggle-button .nav-icon div {
  -webkit-transform: scale(0);
          transform: scale(0); }

.toggle-button:focus {
  outline: none; }

