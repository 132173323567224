/* TitleSection.scss */

section {
  &.title {
    text-align:center;
    @include resp-prop-half("padding-top", calc(#{$offset-g2} * 2));    

    h2 {
      display: inline-block;
      margin: 0 auto;
      padding: 0;
    }
    /* (END) h2 */

    h3 {
      margin: $offset-g2 auto 0 auto;
      display: inline-block;
      position: relative;
    }
    /* (END) h3 */

    .body {      
      margin-top: $offset-g2;
      // font-size:1.2em;
    }

    &.bg-secondary .body {
      font-weight: bold;
    }
  }
}
