/* Variables.scss */

$max-width: 900px;

/* color */
$color_primary: #263762;
$color_secondary: #8b000b;
$color_secondary-light: #e5f7fe;
$color_four: #01395a;

/* base font */
$base-font-size: 1.0em;
$base-font-size-small: 0.9em;
$base-font-family: "Open Sans", Arial, Helvetica, sans-serif;
$base-font-color: #333;
$base-font-line-height: 1.6em;

$color_font-h1: black;
$color_font-h2: black;
$color_font-h3: black;
$color_font-h4: black;
$color_font-default: #333;
$color_font-hyperlink: black;
$color_font-hyperlink-hover: $color_secondary;
$color_font-hyperlink-hover-bg: black;
$color_font-hyperlink-accent: #20b1f3;

$color_border: #ddd;

/* offset: margins & paddings */
$offset-1: 10px;
$offset-2: 20px;
$offset-g1: 15px;
$offset-g2: 30px;

/* responsive breakpoints */
$bp-xlg: 1440px;

$bp-lg: 1200px;
$bp-lg-1: 1199px;

$bp-md: 992px;
$bp-md-1: 991px;

$bp-sm: 768px;
$bp-sm-1: 767px;

$bp-xs: 480px;
$bp-xs-1: 479px;
$bp-tablet: 640px;
$bp-tablet-1: 639px;
$bp-xxs: 400px;
$bp-xxs-1: 399px;

$color_layout-border: #dfdfdf;
$color_layout-border-strong: #ccc;
$color_layout-border-strongest: #000;
// $color_layout-border-priority: $color_priority-border;

/* table */
$table-color-border: $color_layout-border;
$table-color-bg-th: #ccc;
$table-color-bg-tr: white;
$table-color-bg-tr-odd: #f9f9f9;
$table-color-bg-tr-hover: #f3f3f3;