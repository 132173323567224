/* TitleTextImg.css */

section.img-text {
  width:100%;
  padding:0;

  @media (min-width: $bp-tablet) {
    display:flex;
    flex-direction:row;
  }

  .section-inner-img {
    width:50%;

    background-image: url(../../assets/images/bg/bg_jag-porsche-covers.jpg);
    background-position: left center;
    background-repeat: no-repeat;
    background-attachment: scroll;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    background-size: cover;
    -o-background-size: cover;

    @media (max-width: $bp-tablet-1) {
      width:100%;
      height:360px;
      // background-position: left bottom;
    }

    @media (max-width: $bp-xs-1) {
      height:180px;
      // background-position: left bottom;
    }
  }

  .section-inner-text {
    width:50%;

    .content-inner {
      @include resp-prop-half("padding", $offset-g2);
      text-align:left;
      max-width:490px;

      .body {
        font-size:1.2em;
        line-height:1.6em;
      }
    }

    @media (max-width: $bp-tablet-1) {
      width:100%;
      
      .content-inner {
        max-width:auto;

        h2 {
          margin-top:0;
        }
      }
    }
  }

  &#about {
    .section-inner-img {
      background-image: url(../../assets/images/lg/aston-martin-front.jpg);
      background-position: left center;
    }
  }
  &#maintenance {
    .section-inner-img {
      background-image: url(../../assets/images/lg/tyres-checked.jpg);
      background-position: left center;
    }
  }
  &#transport {
    .section-inner-img {
      background-image: url(../../assets/images/lg/car-transport-services.jpg);
      background-position: right center;
    }
  }
  &#accessories {
    .section-inner-img {
      background-image: url(../../assets/images/lg/accessories-bag.jpg);
      background-position: right center;
    }
  }
}



@media (max-width: $bp-tablet-1) {

}
