/* Base.scss */

body {
	//line-height:$base-font-line-height;
	// background:$color_bg-body;
	border-color: $color_layout-border;
	border: 0;
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/* (END) from index.css */

.App {
  text-align: left;
}

section {
  box-sizing: border-box;
  text-align: center;
  @include resp-prop-half("padding", $offset-g2);

  @media (max-width: $bp-sm-1) {
    text-align:left;
    // padding: $offset-g1;    
  }

  > .content-inner,
  > .section-inner {
    display: inline-block;
    max-width: $max-width;
    margin: 0 auto;

    &.body-text {
      text-align:left;
    }
  }
}

.display-none {
  display: none;
}

.display-block {
  display: block;
}

.display-inline-block {
  display: inline-block;
}

/* offsets */
.pad-icon-left {
  margin-left: $offset-1;
}

/* CookieConsent */
.CookieConsent {
  a {
    color:white;
    &:hover {
      color:white;
    }
  }
}
